import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {loadPatientDocument} from "../../actions/patients.actions";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DocumentViewer from "./DocumentViewer";
import {printDate} from "../../utils/helpers";
import {openDocument} from "../../utils/document-utils";
import {showModal} from "../../actions/modal.actions";
import MeetingAudioRecording from "../AudioPlayer/MeetingAudioRecording";
import _ from "lodash";
import axiosInstance from "../../utils/axios-instance";
import {toast} from "react-toastify";
import {TextView} from "./TextView";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Fade, Menu, MenuItem} from "@mui/material";
import externalLinkSvg from "../../images/external-link.svg";
import warningTriangleSvg from "../../images/warning-triangle.svg";
import clintLogoSvg from "../../images/clint-logo.png";
import shareFileSvg from "../../images/share-file.svg";
import {getPanelData} from "../../utils/selectors";
import {showPanel} from "../../actions/panel.actions";

const get = (obj, path, defaultValue) => {
	if (typeof path === 'string') {
		path = path.split('.');
	}

	let current = obj;

	for (let i = 0; i < path.length; i++) {
		if (current === null || current === undefined) {
			return defaultValue;
		}
		current = current[path[i]];
	}

	return (current === undefined || current === null) ? defaultValue : current;
}

const DocumentInfo = ({
						  file,
						  patientId,
						  pageNumber = 1,
						  showModal,
						  textViewEnabled,
						  setTextViewEnabled,
						  searchText,
						  setSearchText,
						  currentSearchEl,
						  totalSearchEl,
						  focusNextOnSearch,
						  focusPreviousOnSearch,
						  changeReadUnread,
						  isRead,
						  setIsRead,
						  isPanelOpen,
						  showPanel,
					  }) => {
	const [audioUrl, setAudioUrl] = useState(null);
	const [downloadingFile, setDownloadingFile] = useState(false);

	//*********************
	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	// End: menu
	//***************

	const fileProps = useMemo(() => [
		{
			name: 'Name',
			value: get(file, 'fileName', 'N/A'),
		}, {
			name: 'Date',
			value: (file && printDate(file.effectiveDate)) || 'N/A',
		}, {
			name: 'Facility',
			value: get(file, 'facility', 'N/A'),
		}, {
			name: 'Type',
			value: get(file, 'folder.name', 'N/A'),
		}, {
			name: 'Status',
			value: get(file, 'status.displayName', 'N/A'),
		},
	], [file]);

	const openInNewWindow = useCallback(() => {
		if (file) {
			openDocument(patientId, file.id, pageNumber);
		}
	}, [file]);

	// const openAudioRecordingModal = useCallback(() => {
	//     if (audioUrl) {
	//         showModal("MEETING_AUDIO_RECORDING_MODAL", {
	//             url: audioUrl,
	//             useAuth: true,
	//         })
	//     }
	// }, [file, audioUrl]);

	useEffect(() => {
		setIsRead(true);
		if (file && file.fileAttachments) {
			const audioFile = file.fileAttachments.find((attachment) => attachment.tags && attachment.tags.includes("meeting_audio"));
			if (audioFile) {
				setAudioUrl(`api/file/download/${file.id}/attachment/${audioFile.id}`);
				return;
			}
		}

		setAudioUrl(null);
	}, [file]);

	const downloadDocument = useCallback(() => {
		const downloadPdf = async (file) => {
			try {
				if (file) {
					setDownloadingFile(true);

					const resp = await axiosInstance.get(`/api/file/download/${file.id}`, {responseType: 'blob'});
					const blob = new Blob([resp.data], {type: 'application/pdf'});
					const link = document.createElement('a');

					if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) {
						link.href = window.URL.createObjectURL(blob);
					} else {
						link.href = URL.createObjectURL(blob);
					}

					link.target = "_blank";
					link.setAttribute(
						'download',
						file.fileName,
					);

					// Append to html link element page
					document.body.appendChild(link);

					// Start download
					link.click();

					// Clean up and remove the link
					link.parentNode.removeChild(link);
				}
			} catch (err) {
				const errMsg = err.response.status === 404 ? "Not found" : _.get(err, 'response.data.errorMessage', err.message);
				// printError("Download error: " + errMsg);
			} finally {
				setDownloadingFile(false);
			}
		};

		if (file && !downloadingFile) {
			downloadPdf(file);
		}
	}, [file, downloadingFile]);

	return (
		<div>
			<div className="document-info-toolbar">
				<div className="info-left">
					{fileProps.map(prop =>
						<div className="prop" key={prop.name}>
							<div className="name">
								{prop.name}
							</div>
							<div className="value" title={prop.value}>
								{prop.value}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="document-info-toolbar info-controls">
				<div className="info-left">
					{audioUrl && (
						<MeetingAudioRecording url={audioUrl} useAuth={true} progressClass="progress-small"/>
					)}
				</div>
				<div className="info-right">
					{textViewEnabled && (
						<div>
							<input type="text" placeholder="Search" onKeyDown={(event) => {
								if (event.key === "Enter") {
									focusNextOnSearch();
								}
							}} value={searchText} onChange={(elem) => setSearchText(elem.target.value)}/>
							({currentSearchEl}/{totalSearchEl})
							&nbsp;<i className="ni ni-arrow-down ni-xs ms-1 cursor-pointer"
									 onClick={focusNextOnSearch}/>
							&nbsp;<i className="ni ni-arrow-up ni-xs ms-1 cursor-pointer"
									 onClick={focusPreviousOnSearch}/>
						</div>
					)}
					<div><label htmlFor="textViewEnabled" className="form-check-label">Text View</label></div>
					<div className="form-check form-switch d-inline-block">
						<input
							id="textViewEnabled"
							name="textViewEnabled"
							type="checkbox"
							className="form-check-input cursor-pointer"
							onChange={() => setTextViewEnabled(!textViewEnabled)}
							checked={textViewEnabled}
						/>
					</div>
					{file && (
						<button
							type="button"
							className={"btn btn-rnd " + (!isRead ? "btn-outline-purple" : "btn-white")}
							disabled={!file}
							onClick={() => changeReadUnread(file)}
						>
							{!isRead ? "Mark as Read" : "Mark as Unread"}
						</button>
					)}
					{/*<div>*/}
					{/*    <Button*/}
					{/*        ref={anchorRef}*/}
					{/*        id="composition-button"*/}
					{/*        aria-controls={open ? 'composition-menu' : undefined}*/}
					{/*        aria-expanded={open ? 'true' : undefined}*/}
					{/*        aria-haspopup="true"*/}
					{/*        onClick={handleToggle}*/}
					{/*    >*/}
					{/*        Dashboard*/}
					{/*    </Button>*/}
					{/*    <Popper*/}
					{/*        open={open}*/}
					{/*        anchorEl={anchorRef.current}*/}
					{/*        role={undefined}*/}
					{/*        placement="bottom-start"*/}
					{/*        transition*/}
					{/*        disablePortal*/}
					{/*    >*/}
					{/*        {({TransitionProps, placement}) => (*/}
					{/*            <Grow*/}
					{/*                {...TransitionProps}*/}
					{/*                style={{*/}
					{/*                    transformOrigin:*/}
					{/*                        placement === 'bottom-start' ? 'left top' : 'left bottom',*/}
					{/*                }}*/}
					{/*            >*/}
					{/*                <Paper>*/}
					{/*                    <ClickAwayListener onClickAway={handleClose}>*/}
					{/*                        <MenuList*/}
					{/*                            autoFocusItem={open}*/}
					{/*                            id="composition-menu"*/}
					{/*                            aria-labelledby="composition-button"*/}
					{/*                            onKeyDown={handleListKeyDown}*/}
					{/*                        >*/}
					{/*                            <MenuItem onClick={handleClose}>Profile</MenuItem>*/}
					{/*                            <MenuItem onClick={handleClose}>My account</MenuItem>*/}
					{/*                            <MenuItem onClick={handleClose}>Logout</MenuItem>*/}
					{/*                        </MenuList>*/}
					{/*                    </ClickAwayListener>*/}
					{/*                </Paper>*/}
					{/*            </Grow>*/}
					{/*        )}*/}
					{/*    </Popper>*/}
					{/*</div>*/}

					<div>
						<Button
							id="fade-button"
							aria-controls={open ? 'fade-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
							endIcon={<KeyboardArrowDownIcon/>}
							className="btn btn-round-6 btn-white"
							variant="outlined"
							disableElevation
							disableRipple
						>
							More Actions
						</Button>
						<Menu
							id="fade-menu"
							MenuListProps={{
								'aria-labelledby': 'fade-button',
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							TransitionComponent={Fade}
						>
							<MenuItem onClick={handleClose}>
								<button
									type="button"
									className={"btn-clean"}
									disabled={!file || downloadingFile}
									onClick={downloadDocument}
								>
									{!downloadingFile ? (
										<i style={{color: "#4a2ecf", marginRight: "10px"}}
										   className="fas fa-lg fa-fw fa-download"/>
									) : (
										<div className="spinner-border text-primary spinner-border-sm me-1"
											 role="status">
											<span className="sr-only">Loading...</span>
										</div>
									)}
									Download
								</button>
							</MenuItem>
							<MenuItem onClick={handleClose}>
								<button
									type="button"
									className={"btn-clean"}
									disabled={!file || !patientId}
									onClick={openInNewWindow}
								>
									<img style={{marginRight: "10px"}} src={externalLinkSvg}/>
									Open in Window
								</button>
							</MenuItem>
							<MenuItem onClick={handleClose}>
								<button
									type="button"
									className={"btn-clean"}
									disabled={!file || !patientId}
									onClick={() => showPanel(isPanelOpen, 'shareFile', {
										patientId: patientId,
										fileId: file.id
									})}
								>
									<img style={{marginRight: "10px"}} src={shareFileSvg}/>
									Share
								</button>
							</MenuItem>
						</Menu>
					</div>
				</div>
			</div>
		</div>
	);
}

const printError = (error) => {
	toast.error(error);
	console.error(error);
}

const PatientDocumentInfoViewer = ({
									   fileInfo,
									   patientId,
									   pageNumer = 1,
									   loadPatientDocument,
									   showModal,
									   changeReadUnread,
									   isRead,
									   setIsRead,
									   isPanelOpen,
									   showPanel,
								   }) => {
	const [file, setFile] = useState(null);
	const [textViewEnabled, setTextViewEnabled] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [currentSearchEl, setCurrentSearchEl] = useState(0);
	const [totalSearchEl, setTotalSearchEl] = useState(0);
	const [fileText, setFileText] = useState(null);
	const documentContentRef = useRef();

	useEffect(() => {
		if (fileInfo && fileInfo.id) {
			loadPatientDocument(fileInfo.id).then(response => {
				// Create a Blob from the PDF Stream
				const file = new Blob([response.data], {type: 'application/pdf'});
				setFile(file);
			});
		} else {
			setFile(null);
		}
	}, [fileInfo]);

	const focusNextOnSearch = () => {
		if (totalSearchEl <= 0) {
			return;
		}
		const elements = documentContentRef.current.getElementsByTagName("mark");
		let nextEl = currentSearchEl + 1;
		if (nextEl > totalSearchEl) {
			nextEl = 1;
		}
		if (elements.length >= nextEl) {
			elements[nextEl - 1].scrollIntoView();
		}
		setCurrentSearchEl(nextEl);
	}

	const focusPreviousOnSearch = () => {
		if (totalSearchEl <= 0) {
			return;
		}
		const elements = documentContentRef.current.getElementsByTagName("mark");
		let nextEl = currentSearchEl - 1;
		if (nextEl < 1) {
			nextEl = totalSearchEl;
		}
		if (elements.length >= nextEl) {
			elements[nextEl - 1].scrollIntoView();
		}
		setCurrentSearchEl(nextEl);
	}

	useEffect(() => {
		if (!fileText || searchText.length == 0) {
			setCurrentSearchEl(0);
			setTotalSearchEl(0);
			return;
		}
		let count = 0;
		for (const filePage of fileText.filePages) {
			count += (filePage.text.match(new RegExp(searchText, "ig"), 'i') || []).length
		}
		if (count > 0) {
			const elements = documentContentRef.current.getElementsByTagName("mark");
			if (elements && elements.length > 0) {
				elements[0].scrollIntoView();
			}
		}

		setCurrentSearchEl(count > 0 ? 1 : 0);
		setTotalSearchEl(count);
	}, [searchText]);

	return (
		<div>
			<DocumentInfo file={fileInfo} patientId={patientId} pageNumber={pageNumer} showModal={showModal}
						  textViewEnabled={textViewEnabled} setTextViewEnabled={setTextViewEnabled}
						  setSearchText={setSearchText} searchText={searchText} currentSearchEl={currentSearchEl}
						  totalSearchEl={totalSearchEl} focusNextOnSearch={focusNextOnSearch}
						  focusPreviousOnSearch={focusPreviousOnSearch}
						  changeReadUnread={changeReadUnread} isRead={isRead} setIsRead={setIsRead}
						  isPanelOpen={isPanelOpen} showPanel={showPanel}/>
			{fileInfo.patientMismatch === true && (
				<div className="file-warning">
					<img src={clintLogoSvg} width="25" height="25"/>&nbsp;<img src={warningTriangleSvg}/>&nbsp;This file may belong to someone other than this client. Under data team review.
				</div>
			)}
			<DocumentViewer visible={!textViewEnabled} file={file} pageNumber={pageNumer}/>
			{textViewEnabled && <TextView fileId={fileInfo.id} searchText={searchText} setFileText={setFileText}
										  fileText={fileText} documentContentRef={documentContentRef}/>}
		</div>
	)
}

const mapStateToProps = (state) => {
	const isPanelOpen = getPanelData(state).isPanelOpen;

	return {
		isPanelOpen
	};
};

const mapDispatchToProps = {
	showModal,
	loadPatientDocument,
	showPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDocumentInfoViewer);